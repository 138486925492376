<template>
  <div style="height: 100%; background: white">
    <el-container style="height: 100%" v-if="aaa">
      <el-header>
        <h1 style="text-align: center; line-height: 60px">开发满意度调查表</h1>
      </el-header>
      <el-main>
        <el-form>
          <el-form-item label="">
            <el-input placeholder="请输入您的手机号进行验证" v-model="phone"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer style="text-align: center">
        <el-button type="success" style="width: 100%" @click="subPhone">确定</el-button>
      </el-footer>
    </el-container>


    <el-container style="height: 100%" v-else>
      <el-header>
        <h1 style="text-align: center; line-height: 60px">开发满意度调查表</h1>
      </el-header>
      <el-main>
        <div style="font-size: 1.25rem">项目名称：{{this.proName}}</div>
        <h2 class="title"><i class="el-icon-caret-right"></i>交付满意度调查</h2>
        <el-card class="box-card" v-for="(item, key) in dev_pleased" :key="key+999">
          <div class="text item" >
            <h2>{{item.item}}</h2>
            <el-form label-width="70px">
              <el-form-item label="项目说明">{{item.itemExp}}</el-form-item>
              <el-form-item label="评分" class="required">
                <el-select v-model="item.itemScore" placeholder="请打分，1~10分">
                  <el-option label="10" value="10"></el-option>
                  <el-option label="9" value="9"></el-option>
                  <el-option label="8" value="8"></el-option>
                  <el-option label="7" value="7"></el-option>
                  <el-option label="6" value="6"></el-option>
                  <el-option label="5" value="5"></el-option>
                  <el-option label="4" value="4"></el-option>
                  <el-option label="3" value="3"></el-option>
                  <el-option label="2" value="2"></el-option>
                  <el-option label="1" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="建议">
                <el-input type="textarea" v-model="item.suggest"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
        <h2 class="title"><i class="el-icon-caret-right"></i>产品体验满意度调查</h2>
        <el-card class="box-card" v-for="(item, index) in product_pleased" :key="index">
          <div class="text item">
            <h2>{{item.item}}</h2>
            <el-form label-width="70px">
              <el-form-item label="项目说明">{{item.itemExp}}</el-form-item>
              <el-form-item label="评分" class="required">
                <el-select v-model="item.itemScore" placeholder="请打分，1~10分">
                  <el-option label="10" value="10"></el-option>
                  <el-option label="9" value="9"></el-option>
                  <el-option label="8" value="8"></el-option>
                  <el-option label="7" value="7"></el-option>
                  <el-option label="6" value="6"></el-option>
                  <el-option label="5" value="5"></el-option>
                  <el-option label="4" value="4"></el-option>
                  <el-option label="3" value="3"></el-option>
                  <el-option label="2" value="2"></el-option>
                  <el-option label="1" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="建议">
                <el-input type="textarea" v-model="item.suggest"></el-input>
              </el-form-item>
            </el-form>

          </div>
        </el-card>
      </el-main>
      <el-footer style="text-align: center">
        <el-button type="success" style="width: 100%" @click="subPleased">提交</el-button>
      </el-footer>
    </el-container>


  </div>
</template>
<script>
import {getPleased, subPhone, subPleased} from "@/api/project_management/my_project";
export default {
  props: [],
  components: {},
  data() {
    return {
      proName: '',
      phone: '',
      aaa: true,
      dev_pleased: [],
      dev_pleased_last: [],
      product_pleased: [],
      product_pleased_last: [],
    }
  },
  created() {

  },
  mounted() {
    this.getPleased()
  },
  methods: {
    getPleased(){
      let Base64 = require('js-base64').Base64
      let pro_id = JSON.parse(Base64.decode(this.$route.query.params))
      pro_id.need_power = 0
      console.log(pro_id)
      getPleased(pro_id).then((result)=>{
        console.log(result)
        this.dev_pleased = JSON.parse(result.data.dev_pleased_json)
        this.dev_pleased_last = this.dev_pleased[5]
        this.dev_pleased.splice(5,1)

        this.product_pleased = JSON.parse(result.data.product_pleased_json)
        this.product_pleased_last = this.product_pleased[9]
        this.product_pleased.splice(9,1)

        this.proName = result.data.project.project_title
      })

    },
    subPhone(){
      if (!this.phone){
        this.$confirm('手机号码不能为空！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      let subData = {
        params: this.$route.query.params,
        phone: this.phone
      }
      subPhone(subData).then((result)=>{
        console.log(result)
        this.aaa = false
      })
    },
    subPleased(){
      for (let i = 0; i < this.dev_pleased.length; i++) {
        if (!this.dev_pleased[i].itemScore){
          this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
      }
      for (let i = 0; i < this.product_pleased.length; i++) {
        if (!this.product_pleased[i].itemScore){
          this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
      }

      this.dev_pleased_last.itemScore = (parseFloat(this.dev_pleased[0].itemScore)*4 + parseFloat(this.dev_pleased[1].itemScore)*2
          + parseFloat(this.dev_pleased[2].itemScore) + parseFloat(this.dev_pleased[3].itemScore)*2 + parseFloat(this.dev_pleased[4].itemScore)).toFixed(1)
      this.product_pleased_last.itemScore = (parseFloat(this.product_pleased[0].itemScore)*2 + parseFloat(this.product_pleased[1].itemScore)*1.2
          + parseFloat(this.product_pleased[2].itemScore) + parseFloat(this.product_pleased[3].itemScore)*0.8 + parseFloat(this.product_pleased[4].itemScore)*0.5
          + parseFloat(this.product_pleased[5].itemScore)*0.5 + parseFloat(this.product_pleased[6].itemScore)*0.7 + parseFloat(this.product_pleased[7].itemScore)*0.3
          + parseFloat(this.product_pleased[8].itemScore)*3).toFixed(1)
      let subData = {}
      subData.params = this.$route.query.params
      subData.dev_pleased_json = [...this.dev_pleased]
      subData.product_pleased_json = [...this.product_pleased]
      subData.dev_pleased_json.push(this.dev_pleased_last)
      subData.product_pleased_json.push(this.product_pleased_last)
      subData.dev_pleased_json = JSON.stringify(subData.dev_pleased_json)
      subData.product_pleased_json = JSON.stringify(subData.product_pleased_json)
      subData.phone = this.phone
      subPleased(subData).then((result)=>{
        console.log(result)
        this.$message({
          message: result.msg,
          type: 'success'
        });
      })
    },
  },
}
</script>

<style type="scss" scoped>
.box-card{
  margin: 10px 0;
}
</style>
<style>
.title{
  border-bottom: 1px solid;
  margin: 10px 0px;
  padding: 10px 0;
  color: #4C4D7B;
  font-weight: 600;
}
</style>